import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../layout';
import Contact from '../components/Contact';
import ConfigContext from '../context/ConfigContext';

function ContactPage() {
  const config = useContext(ConfigContext);

  return (
    <Layout>
      <div className='about-container'>
        <Helmet title={`Contact | ${config.siteTitle}`} />
        <Contact />
      </div>
    </Layout>
  );
}

export default ContactPage;
