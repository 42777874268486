import React, { useState } from 'react';

const Contact = () => {
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');

  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(name, subject);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact_form',
        name,
        subject,
      }),
    })
      .then(() => console.log('success'))
      .catch((error) => alert(error));
  };
  return (
    <div className='mt-8 max-w-3xl ml-auto mr-auto'>
      {/* <h1 className='text-2xl text-base mb-2'>Contact</h1> */}
      <p className='text-2xl text-center'>
        You can reach me out by email
        <br />
        <a className='text-blue-600' target='_blank' href='mailto:romero.pozo2012@gmail.com'>
          here!
        </a>
      </p>
      {/*<form
        className='grid grid-cols-1 gap-6'
        data-netlify='true'
        name='pizzaOrder'
        method='post'
        onSubmit={handleSubmit}
      >
        <label className='block' htmlFor='name'>
          <span className='text-gray-700'>Name</span>
          <input
            className='mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0'
            id='name'
            type='text'
            name='name'
            onChange={(event) => setName(event.target.value)}
          />
        </label>
        <label className='block' htmlFor='subject'>
          <span className='text-gray-700'>Subject</span>
          <textarea
            className='mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0'
            id='subject'
            rows={4}
            name='name'
            onChange={(event) => setSubject(event.target.value)}
          />
        </label>
        <input type='hidden' name='form-name' value='contact_form' />
        <button className='bg-blue-600 text-white rounded lowercase text-md py-1' type='submit'>
          Submit
        </button>
      </form>*/}
    </div>
  );
};

export default Contact;
